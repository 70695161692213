<template>
	<h1 class="text-4xl font-medium">
		<slot></slot>
	</h1>
</template>

<script>
export default {}
</script>

<style></style>
