<template>
	<DashboardTable state="categories" name="Categories" />
</template>

<script>
import DashboardTable from "@/components/DashboardTable.vue"
export default {
	components: { DashboardTable },
}
</script>

<style></style>
