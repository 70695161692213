<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		class="bi bi-caret-up-fill h-full w-full"
		viewBox="2.2 4.52 11.6 7.48">
		<path
			d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path>
	</svg>
</template>
